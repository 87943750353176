import React, { ButtonHTMLAttributes, PropsWithChildren } from "react";
import style from "./chatMessageItem.module.scss";
import clsx from "clsx";

interface IProps {
  messageData: { text: string; isUserMessage: boolean };
}

const ChatMessageItem: React.FC<IProps> = ({ messageData }) => {
  return (
    <div
      className={clsx(style.chatItemWrapper, {
        [style.messageItemWrapperRight]: messageData.isUserMessage,
      })}
    >
      <pre
        className={clsx(style.messageItem, {
          [style.messageItemLeft]: !messageData.isUserMessage,
        })}
      >
        {messageData.text}
      </pre>
    </div>
  );
};

export default ChatMessageItem;

import React, { ButtonHTMLAttributes, PropsWithChildren } from "react";
import style from "./Button.module.scss";
import clsx from "clsx";

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
}

const Button: React.FC<PropsWithChildren<IProps>> = ({
  children,
  disabled,
  ...rest
}) => {
  return (
    <button
      className={clsx(style.button, {
        [style.disabled]: disabled,
      })}
      {...rest}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;

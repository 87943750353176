import React, { useLayoutEffect, useState, useEffect } from "react";
import axios from "axios";
import style from "./AiBot.module.scss";
import Input from "../Common/Input/Input";
import clsx from "clsx";
import { ReactComponent as SendMessageSvg } from "../../Assets/Svg/sendMessage.svg";
import ChatMessageItem from "./chatMessageItem/ChatMessageItem";

const testChatArray = [
  { text: "testetst dgfdf jdsdjjer djfrru djfjf", isUserMessage: true },
  { text: "testetst dgfdf jdsdjjer djfrru djfjf", isUserMessage: true },
  { text: "testetst", isUserMessage: false },
  {
    text: "testetst dgfdf jdsdjjer djfrru djfjf fffffff st dgfdf jdsdjjer djfrru djfjfst dgfdf jdsdjjer djfrru djfjfst dgfdf jdsdjjer djfrru djfjfst dgfdf jdsdjjer djfrru djfjf",
    isUserMessage: false,
  },
  { text: "teste", isUserMessage: true },
];

interface messageArray {
  text: string;
  isUserMessage: boolean;
}

const token = process.env.REACT_APP_STACK_AI_TOKEN;

const AiBot: React.FC = () => {
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [messageArray, setMessageArray] = useState<messageArray[]>([]);

  const [inputText, setInputText] = useState("");

  const sendMessage = async () => {
    if (inputText.trim() && !isMessageLoading) {
      setIsMessageLoading(true);

      const newUserMessage = { text: inputText.trim(), isUserMessage: true };
      const newLoadingMessage = {
        text: "I’m currently processing over X hours of video and podcasts to find your answer. This will probably take me around 3 minutes. Please stand by.",
        isUserMessage: false,
      };

      setMessageArray((prevArray) => [
        ...prevArray,
        newUserMessage,
        newLoadingMessage,
      ]);
      setInputText("");
      getResponse();
    }
  };

  const getResponse = async () => {
    try {
      const data = {
        "in-0": inputText,
      };

      const response = await axios.post(
        "https://www.stack-inference.com/run_deployed_flow?flow_id=656f49bc26c0261adc17e60e&org=38fc00d6-4a2e-4b81-a9b4-26567fd378ed",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data["out-0"]);

      setMessageArray((prevMessageArray) => {
        const newMessageArray = [...prevMessageArray];
        newMessageArray[newMessageArray.length - 1].text =
          response.data["out-0"];
        return newMessageArray;
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsMessageLoading(false);
    }
  };

  const onEnterClickHandler = (e: any) => {
    if (e.key !== "Enter") return;
    sendMessage();
  };

  return (
    <div className={style.container}>
      <div className={style.chatBlock}>
        {messageArray &&
          messageArray.map((el: any, index) => (
            <ChatMessageItem key={index} messageData={el} />
          ))}
      </div>
      <div className={style.inputBlock}>
        <Input
          value={inputText}
          onInputChange={setInputText}
          placeholder="Your message"
          onKeyUp={onEnterClickHandler}
        />
        <SendMessageSvg
          className={clsx(style.sendMessageSvg, {
            [style.disabled]: isMessageLoading,
          })}
          onClick={sendMessage}
        />
      </div>
    </div>
  );
};

export default AiBot;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import style from "./app.module.scss";
import AiBot from "./Component/aiBot/AiBot";
import GenerateMessagePage from "./Component/GenerateMessagePage";

function App() {
  return (
    <div className={style.container}>
      <Router>
        <Routes>
          <Route
            path="/generate-message/:id"
            element={<GenerateMessagePage />}
          />
          <Route path="/ai-bot" element={<AiBot />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

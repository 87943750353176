import React, { InputHTMLAttributes, ChangeEvent, useRef } from "react";
import style from "./Input.module.scss";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  onInputChange: (value: string) => void;
  value: string;
  placeholder?: string;
}

const Input: React.FC<InputProps> = ({
  label,
  onInputChange,
  value,
  placeholder,
  ...props
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onInputChange) {
      onInputChange(event.target.value);
    }
  };

  return (
    <div className={style.container}>
      {label && <label>{label}</label>}
      <input
        className={style.input}
        onChange={handleInputChange}
        value={value}
        autoComplete="off"
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

export default Input;

import React, { InputHTMLAttributes, ChangeEvent, useRef } from "react";
import style from "./textArea.module.scss";

interface TextAreaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  onTextAreaChange: (value: string) => void;
  value: string;
  placeholder?: string;
}

const TextArea: React.FC<TextAreaProps> = ({
  label,
  onTextAreaChange,
  value,
  placeholder,
  ...props
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleContainerClick = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onTextAreaChange) {
      onTextAreaChange(event.target.value);
    }
  };

  return (
    <div className={style.container} onClick={handleContainerClick}>
      {label && <label>{label}</label>}
      <textarea
        ref={textareaRef}
        className={style.textArea}
        onChange={handleTextAreaChange}
        value={value}
        autoComplete="off"
        placeholder={placeholder}
        {...props}
      />
    </div>
  );
};

export default TextArea;

import style from "./generateMessageBlock.module.scss";
import React, { useRef, useEffect, useState } from "react";
import clsx from "clsx";
import { isMobile } from "react-device-detect";

interface IGenerateMessageBlockProps {
  generateMessage: string;
}

const GenerateMessageBlock: React.FC<IGenerateMessageBlockProps> = ({
  generateMessage,
}) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [blockMaxHeight, setBlockMaxHeight] = useState(0);
  const [messageText, setMessageText] = useState("");
  const [autoScroll, setAutoScroll] = useState(true);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const calculateBlockMaxHeight = () => {
      if (componentRef.current) {
        const windowHeight = window.innerHeight;
        const rect = componentRef.current.getBoundingClientRect();

        if (rect.top + 300 < windowHeight) {
          setBlockMaxHeight(windowHeight - rect.top - 100);
        } else {
          setBlockMaxHeight(400);
        }
      }
    };

    calculateBlockMaxHeight();
    window.addEventListener("resize", calculateBlockMaxHeight);

    return () => {
      window.removeEventListener("resize", calculateBlockMaxHeight);
    };
  }, [componentRef, isOpen]);

  useEffect(() => {
    setMessageText(generateMessage);
  }, [generateMessage]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (autoScroll && componentRef.current) {
      componentRef.current.scrollTop = componentRef.current.scrollHeight;
    }
  }, [autoScroll, messageText]);

  const handleScroll = () => {
    if (componentRef.current) {
      const isAtBottom =
        componentRef.current.scrollTop + componentRef.current.clientHeight ===
        componentRef.current.scrollHeight;

      setAutoScroll(isAtBottom);
    }
  };

  return (
    <div
      className={clsx(style.generateMassageBlockInnerWrapper, {
        [style.generateMassageBlockInnerWrapperShow]: isOpen,
      })}
    >
      <div
        className={clsx(style.generateMassageBlockInner, {
          [style.generateMassageBlockInnerShow]: isOpen,
          [style.generateMassageBlockInnerVisibleScrollBar]: isMobile,
        })}
        ref={componentRef}
        style={blockMaxHeight ? { maxHeight: blockMaxHeight } : {}}
        onScroll={handleScroll}
      >
        <pre>{messageText}</pre>
      </div>
    </div>
  );
};

export default GenerateMessageBlock;

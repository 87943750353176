import React, { useLayoutEffect, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import style from "./generateMessagePage.module.scss";
import TextArea from "./Common/TextArea/TextArea";
import Button from "./Common/Button/Button";
import GenerateMessageBlock from "./GenerateMessageBlock/GenerateMessageBlock";
import copy from "clipboard-copy";
import Spinner from "./Common/Spinner/Spinner";
import { ReactComponent as CopySvg } from "../Assets/Svg/copy.svg";
import { ReactComponent as CheckMark } from "../Assets/Svg/checkMark.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { HfInferenceEndpoint } from "@huggingface/inference";

const token = process.env.REACT_APP_GO_HIGH_LEVEL_TOKEN;

const escapeText = (previousText: string, textChunk: string) => {
  if (textChunk.endsWith("</s>") || textChunk.endsWith("<s>")) {
    // const newText = textChunk.replaceAll("</s>", "").replaceAll("<s>", "");
    // return previousText + newText;
    return previousText;
  }

  // if (previousText.length === 0 && textChunk !== "\n") {
  //   const newText = textChunk.replaceAll("\n", "");
  //   return previousText + newText;
  // }

  // if (previousText.length === 0 && textChunk !== ".") {
  //   const newText = textChunk.replaceAll(".", "");
  //   return previousText + newText;
  // }

  // if (previousText.endsWith(" ") || textChunk === " ") {
  //   return previousText;
  // }

  // if (textChunk.includes("�")) {
  //   return previousText;
  // }
  if (previousText.endsWith("\n\n\n") && textChunk === "\n") {
    const newText = textChunk.replaceAll("\n", "");

    return previousText + newText;
  }

  return previousText + textChunk;
};

const GenerateMessagePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [isLocationIdValid, setIsLocationIdValid] = useState(false);
  const [isLocationIdValidLoading, setIsLocationIdValidLoading] =
    useState(true);
  const [messagePromptValue, setMessagePromptValue] = useState("");

  const [generateMessage, setGenerateMessage] = useState("");

  const [isLoadingGenerateMessage, setIsLoadingGenerateMessage] =
    useState(false);

  const [isCopied, setIsCopied] = useState(false);

  const [isAgree, setIsAgree] = useState(false);

  useLayoutEffect(() => {
    const checkLocationId = async () => {
      if (!id) return;
      const url = `https://rest.gohighlevel.com/v1/locations/${id}`;

      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try {
        setIsLocationIdValidLoading(true);

        const response = await axios.get(url, { headers });
        if (response.data.id === id) {
          setIsLocationIdValid(true);
          setIsLocationIdValidLoading(false);
        }
      } catch (error: any) {
        setIsLocationIdValid(false);
        setIsLocationIdValidLoading(false);
      }
    };

    checkLocationId();
  }, [id]);

  const handleMessagePromptValueChange = (value: string) => {
    setMessagePromptValue(value);
  };

  const handleGenerateMessage = () => {
    if (messagePromptValue.trim().length) {
      setGenerateMessage("");
      getGeneratedMessage(messagePromptValue + "\n===\n");
    }
  };

  const handleClearMessageInput = () => {
    setMessagePromptValue("");
  };

  const handleCopyClick = async () => {
    if (!isCopied) {
      await copy(generateMessage);
      setIsCopied(true);
      toast.success("Copy success");
      toast.onChange((info) => {
        if (info.status === "removed") {
          setIsCopied(false);
        }
      });
    }
  };

  const updateText = (chunk: string) => {
    setGenerateMessage((previousText) => escapeText(previousText, chunk));
  };

  const getGeneratedMessage = async (prompt: string) => {
    setIsLoadingGenerateMessage(true);

    const hf = new HfInferenceEndpoint("https://clickpresi.com");
    const stream = hf.textGenerationStream({
      inputs: prompt,
      parameters: {
        max_new_tokens: 1024,
        temperature: 0.7,
        //@ts-ignore
        top_a: 0.2,
        repetition_penalty: 1.15,
        return_full_text: false,
      },
    });
    try {
      for await (const r of stream) {
        updateText(r.token.text);
      }
    } catch (error) {
      console.log(error);
    }

    setIsLoadingGenerateMessage(false);
  };

  const handleAgreeClick = () => {
    if (isLoadingGenerateMessage) {
      return;
    }
    setIsAgree(!isAgree);
  };

  const getBtnText = () => {
    if (isLoadingGenerateMessage) {
      return "Generating...";
    }
    if (generateMessage) {
      return "Regenerate message";
    }
    return "Generate message";
  };

  if (isLocationIdValidLoading) {
    return (
      <div className={style.loadingSpinnerContainer}>
        <Spinner />
        <div className={style.loadingSpinnerText}>Loading...</div>
      </div>
    );
  }

  return (
    <>
      {isLocationIdValid ? (
        <div className={style.container}>
          <ToastContainer pauseOnHover={false} />
          <TextArea
            value={messagePromptValue}
            onTextAreaChange={handleMessagePromptValueChange}
            placeholder="Please enter your prompt to generate a message"
          />
          <div className={style.agreementBlock}>
            <div
              className={style.checkMarkBlock}
              onClick={() => handleAgreeClick()}
            >
              {isAgree && <CheckMark />}
            </div>
            I understand this is meant to be used as a ROUGH DRAFT and it is my
            responsibility to make sure my emails are factually accurate and
            compliant with any and all advertising regulations
          </div>
          <div className={style.buttonsBlock}>
            <Button onClick={handleClearMessageInput}>Clear</Button>
            <Button
              onClick={handleGenerateMessage}
              disabled={isLoadingGenerateMessage || !isAgree}
            >
              {getBtnText()}
            </Button>
          </div>
          {generateMessage && (
            <>
              <div className={style.generateMassageBlock}>
                <GenerateMessageBlock generateMessage={generateMessage} />
                {!isLoadingGenerateMessage && (
                  <div className={style.copyBtn} onClick={handleCopyClick}>
                    <CopySvg />
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={style.container}>Non valid location id</div>
      )}
    </>
  );
};

export default GenerateMessagePage;
